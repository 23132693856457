.side-nav {
    position: absolute;
    left: 0;
    top: 0;
    width: 65px;
    height: 100%;
    background: #FFFFFF;
    color: black;
    text-align: center;
    z-index: 1030;
}

.side-nav svg {
    height: 18px;
    width: 18px;
}

.fa-chevron-right {
    height: 12px !important;
    width: 12px !important;
}