iframe {
    border: none;
    width: 100%;
    height: 100%;
    z-index: inherit;
}

.iframe-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.childcontainer {
    z-index: 1;
}

/* #modalOne{
    display:none;
}
#modalTwo{
    display:none;
} */