.timeout-modal-container {
  position: relative;
  height: auto;
  top: -15px;
  margin-bottom: -10px;
}

.container-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
}

.countdown-text {
  font-weight: 700;
  font-size: 30px;
}

.last-paragraph {
  line-height: 28px;
}

.timeout-dialog > .k-dialog > .k-dialog-content {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.timeout-dialog .k-dialog-buttongroup {
  border: none !important;
  margin-bottom: 24px!important;
  margin-top: 10px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  margin-right: -24px!important;
  padding-right: 24px!important;
}

.timeout-dialog .k-dialog-titlebar {
  border: none !important;
  color: #222222 !important;
  padding-top: 25px !important;
}
