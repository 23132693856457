.lead {
  font-family: Segoe UI;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.modal-container {
  min-height: 350px;
  position: relative;
  overflow-y: scroll;
  max-height: 70vh;
}

.environment-action-bar {
  padding: 0px 5px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.modal-container .list-group {
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.list-item {
  list-style: none;
  font-size: 1.25rem;
  font-weight: 300;
}

.radio-button {
  padding-bottom: 0px !important;
}
