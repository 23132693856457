.loader {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 30%;
  text-align: center;
  z-index: 9999;
  background: none;
}

.spinner {
  position: fixed;
  height: 50px;
  /* left: 98%;
  top: 75px;
  right: 2%; 
  left: 1770px;*/
  top: 75px;
  right: 2%;
  text-align: right;
  z-index: 1;
  background: none;
}

.loading-subtle {
  position: fixed;
  top: 60px;
  right: 15px;
  color: #fff;
  z-index: 9999;
  display: none;
}

.backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #FFF;
  opacity: .75;
}